import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VariablesService } from '../variables.service';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css']
})
export class PricingComponent implements OnInit {
  checks = []
  books = []
  id = 0
  constructor(private route: ActivatedRoute, private vars: VariablesService) { }

  ngOnInit(): void {
    let id = this.route.snapshot.params.id
    this.id = id == undefined || id == 0 ? 0 : 1

    this.books = [
      "Please review calendar, pictures, and video before booking. Any questions please call or email.",
      'Send $500 deposit to “Shore Rentals” PO Box 202, Ridgewood NJ 07451.',
      'We will email you the lease and pay plan.'

    ]

    this.checks = [
      "Payment can be spread over several months",
      "Come visit anytime. No appointment necessary",
      "Affordable $500 security deposit",
    ]

  }

}
