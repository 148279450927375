import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { AngularFireDatabase } from '@angular/fire/database';
import { Promise } from 'q';

@Injectable({
  providedIn: 'root'
})
export class VariablesService {

  constructor(private db: AngularFireDatabase) { }

  getAssetsLength(){
    return [47, 31, 33, 29]
  }

  getItems(){
    return Promise((resolve) => {
      let items = ["Beach house", "Unit 1", "Unit 2", "Unit 3"]
      let amenities = [
        "10 guests • 4 bedrooms",
        "8 guests • 3 bedrooms",
        "8 guests • 3 bedrooms",
        "4 guests • 2 bedrooms",
      ]
      let sleep = [
  
        "4 bedroom sleeps 10<br>8 person jacuzzi",
        "3 bedroom sleeps 8",
        "3 bedroom sleeps 8",
        "2 bedroom sleeps 4"
  
      ]
      let address = [
        "23 Farragut Ave<br>Seaside Park, NJ",
        "30 Farragut Ave<br>Seaside Park, NJ",
        "30 Farragut Ave<br>Seaside Park, NJ",
        "30 Farragut Ave<br>Seaside Park, NJ"
      ]
      let frames = [
        `<iframe width="854" height="640" border="0" frameborder="0" src="https://tour-this-com.seehouseat.com/1927471?a=1" allowfullscreen="true" allow="camera https://tour-this-com.seehouseat.com; microphone https://tour-this-com.seehouseat.com"><a href="https://tour-this-com.seehouseat.com/1927471?a=1" target="_top">View this tour in a new window.</a></iframe>`,
        `<iframe width="854" height="640" border="0" frameborder="0" src="https://tour-this-com.seehouseat.com/1927472?a=1" allowfullscreen="true" allow="camera https://tour-this-com.seehouseat.com; microphone https://tour-this-com.seehouseat.com"><a href="https://tour-this-com.seehouseat.com/1927472?a=1" target="_top">View this tour in a new window.</a></iframe>`,
        `<iframe width="854" height="640" border="0" frameborder="0" src="https://tour-this-com.seehouseat.com/1927473?a=1" allowfullscreen="true" allow="camera https://tour-this-com.seehouseat.com; microphone https://tour-this-com.seehouseat.com"><a href="https://tour-this-com.seehouseat.com/1927473?a=1" target="_top">View this tour in a new window.</a></iframe>`,
        `<iframe width="854" height="640" border="0" frameborder="0" src="https://tour-this-com.seehouseat.com/1927477?a=1" allowfullscreen="true" allow="camera https://tour-this-com.seehouseat.com; microphone https://tour-this-com.seehouseat.com"><a href="https://tour-this-com.seehouseat.com/1927477?a=1" target="_top">View this tour in a new window.</a></iframe>`
      ]
      let result = []
      var sub: Subscription
      sub = this.db.object("prices").valueChanges().subscribe((prices:any) => {
        sub.unsubscribe()
        items.forEach((item, index) => {
          result.push({
            item: item,
            details: amenities[index],
            price: prices[index],
            address: address[index],
            sleep: sleep[index],
            frame: frames[index]
          })
        })
        resolve(result)
      })
    })
    
    
  }

  getMap(){
    let a = "ChIJBd7QmyuawYkRDDbfmc64egs"
    let b = "ChIJiQHmhCuawYkRdn5Tzt2wa4M"
    return [a,b,b,b]
  }

}
