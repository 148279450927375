import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.css']
})
export class FeaturesComponent implements OnInit {
  features = []
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {

    let id = this.route.snapshot.params.id

    let a = ["shower", "car", "television", "cutlery", "futbol-o","fire", "wifi", "thermometer-empty", "bicycle", "music", "inbox"]
    let b = [
      "Outdoor shower for<br>when you come off beach", //1
      "Off street parking", //2
      "New flat screen<br>tv's in every room", //3
      "Full size kitchen<br>fully equipped", //4
      "Large back yard<br>for play time", //5
      "Fire pit for night<br>time enjoyment", //6
      "High-speed internet<br>connection", //7
      "Brand new central<br>air conditioning", //8
      "Bycicles provided for<br>your whole group", //9
      "Outdoor bluetooth<br>speaker system", //10
      "Washer and dryer<br>available" //11
    ]

    b.forEach((item, index) => {
      var back = false 
      if (id == "3") {
        back = index == 1 || index == 4 || index  == 5 || index == 10 || index == 11
      }
      if (!back){
        this.features.push({icon: a[index], text: item})
      }
      
    })
  }

}
