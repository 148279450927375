<div class="row bg-half">
    <div class="col">
        <div class="container">
            <div id="contact-title" class="row">
                <div class="col">
                    <h4>Book now</h4>
                </div>
            </div>
            <div id="contact" class="row justify-content-center">
                <div class="col-10 col-sm-10 col-md-8 col-lg-6 col-xl-5">
                    <h5>Deposit $500 to "Shore Rentals"<br>PO Box 202 Ridgewood, NJ 07451<br><br>We will send you the lease and payment plan.</h5>
                    <div class="divider"><span>OR</span></div>
                    <h5>Contact us:</h5>
                    <br>
                    <h5><i class="fa fa-phone fa-fw"></i> (732) 278-4355</h5>
                    <h5><i class="fa fa-envelope fa-fw"></i> seasideheights16@gmail.com</h5>
                    <br><br><br><br>
                    <!-- <div class="divider"><span>OR</span></div> -->
                    <div class="alert alert-secondary hide" id="p-contact">Fill out the form below. We will get back to you as soon as possible.</div>

                    <div id="card-contact" class="card hide">
                        <form (ngSubmit)="submit(f)" #f="ngForm">
                            <div class="form-group">
                              <label for="exampleInputEmail1">Name</label>
                              <input type="text" placeholder="Name" class="form-control" id="name" required>
                            </div>
                            <div class="form-group">
                                <label for="phone">Phone number</label>
                                <input required type="text" mask="(000) 000-0000" placeholder="Phone number" class="form-control" id="phone">
                            </div>
                            <div class="form-group">
                              <label for="email">Email address</label>
                              <input required type="email" placeholder="Email" class="form-control" id="email">
                            </div>
                            <div class="form-group">
                                <label for="inquiry">Inquiry</label>
                                <textarea required class="form-control" id="inquiry" rows="5"></textarea>
                            </div>
                            <button type="submit" class="full-width btn btn-primary"><i class="fa fa-paper-plane fa-fw"></i> Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>