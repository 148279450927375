<app-admin-navbar *ngIf="admin"></app-admin-navbar>

<div class="container">
    <div class="row justify-content-center">
        <div class="col-11 col-sm-11 col-md-9">

            <div *ngIf="admin" class="row">
                <div class="col">
                    <h5 id="item" [innerHtml]="item"></h5>
                    <br>
                    <div class="alert alert-info"><i class="fa fa-exclamation-triangle fa-fw"></i> Simply click on the date to update availability. It will be updated in real-time, so you don't need to save it or anything.</div>
                </div>
            </div>

            <div *ngIf="!admin" id="feature-title" class="row">
                <div class="col">
                    <h4>Availability</h4>
                </div>
            </div>

            <div id="row-title" class="row justify-content-center">
                <div class="col-3 col-sm-3 col-md-2">
                    <button (click)="changeMonth(false)" class="full-width btn btn-primary"><i class="fa fa-chevron-left fa-lg"></i></button>
                </div>
                <div class="col-5">
                    <h3>{{ title }}</h3>
                </div>
                <div class="col-3 col-sm-3 col-md-2 col">
                    <button (click)="changeMonth(true)" class="full-width btn btn-primary"><i class="fa fa-chevron-right fa-lg"></i></button>
                </div>
            </div>

            <div id="loading" *ngIf="loading">
                <h5><i class="fa fa-spinner fa-pulse fa-lg"></i></h5>
            </div>
            <div *ngIf="!loading">
                <div class="row">
                    <div *ngFor="let day of days" class="col days">
                        {{ day }}
                    </div>
                </div>

                <div *ngFor="let row of result; let i = index" class="row">
                    <div (click)="updateDate(i, j)" [ngClass]="{'crossed': col.available == 1, 'available': col.available == 0, 'inactive': !col.fill, 'date-active': col.fill, 'admin': admin}" *ngFor="let col of row; let j = index" class="col date">
                        <span *ngIf="col.fill">{{ col.date }}</span>
                    </div>
                </div>
            </div>
                
            <div id="spare" *ngIf="admin" class="row">
                <div class="col">

                </div>
            </div>


        </div>
    </div>
</div>