<app-navbar></app-navbar>

<div id="cont" class="container">

    <div class="row" id="title">
        <div class="col">
            <h1><b>4 Beach Rentals for the summer<br>(Long and short term)</b><br></h1>
            <br>
            <h4><b>3 houses from Beach!<br>Brand new and newly renovated</b>!</h4>
            <br>
            <h4><b>(Total 12 bedrooms on 4 Units rent 2,3, or 4 Bedrooms or rent them all)</b></h4>
            <br>
            <br>
            <h5><b>Please see our customer reviews below</b></h5>
            
        </div>
    </div>

    <div (click)="toPage(i)" *ngFor="let item of items; let i = index" class="row justify-content-around">
        <div class="col-11 col-sm-11 col-md-12 col-lg-10 col-item">
            <div class="card">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-7 col-img">
                        <div class="box-image">
                            <img src="/assets/new/{{ i }}/medium/0.jpg" width="100%" alt="">
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-5">
                        <div class="box-details">
                            <p class="address">{{ item.item }}</p>
                            <p class="details" innerHtml="{{item.sleep}}<br><br>{{ item.address }}"></p>
                            <p class="price">${{ item.price | number:'1.0-0' }} / week</p>
                            <button routerLink="/details/{{ i }}" class="btn btn-outline-primary">See availability</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col hide">
            <div class="card">
                <iframe width="100%" class="map" frameborder="0" style="border:0" src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJBd7QmyuawYkRDDbfmc64egs&key=AIzaSyB40H1-Jza7pliWzMjI62OC3w67-tmKwQM&zoom=14" allowfullscreen></iframe>
            </div>
        </div>
    </div>

</div>

<app-features></app-features>



<div id="reviews" class="row justify-content-center">
    <div class="col-12 col-sm-12 col-md-8 col-lg-7 col-xl-6">
        <br>
        <h4 id="testi-title">What our customers say</h4>
        <br><br>
        <div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item" [ngClass]="{'active': i == 0}" *ngFor="let testimonial of testimonials; let i = index">
                    <div class="carousel-box">
                        <div class="carousel-wrap">
                            <div class="container">
                                <div class="row justify-content-center">
                                    <div class="col-10 col-md-10 col-lg-9 col-testi">
                                        <p class="p-star"><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i></p>
                                        <h5>“{{ testimonial.comment }}”</h5>
                                        <br>
                                        <h5 class="testi-name">
                                            <b>{{ testimonial.name }}</b>
                                            
                                        </h5>
                                        
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
        
            </div>
            <a class="carousel-control-prev " href="#carouselExampleCaptions" role="button" data-slide="prev">
                <i class="fa fa-chevron-left carousel-btn"></i>
            </a>
            <a class="carousel-control-next " href="#carouselExampleCaptions" role="button" data-slide="next">
                <i class="fa fa-chevron-right carousel-btn"></i>
            </a>
        </div>
        <br>
    </div>

    
</div>

<app-pricing></app-pricing>

<app-explanation></app-explanation>

<app-contact></app-contact>


<app-footer></app-footer>