<app-navbar></app-navbar>

<div class="container">
    <div class="row justify-content-around">
        <div class="col-11 col-sm-11 col-md-6">
            <iframe *ngIf="id == 0" width="100%" height="750" border="0" frameborder="0" src="https://tour-this-com.seehouseat.com/1927471?a=1" allowfullscreen="true" allow="camera https://tour-this-com.seehouseat.com; microphone https://tour-this-com.seehouseat.com"><a href="https://tour-this-com.seehouseat.com/1927471?a=1" target="_top">View this tour in a new window.</a></iframe>
            <iframe *ngIf="id == 1" width="100%" height="750" border="0" frameborder="0" src="https://tour-this-com.seehouseat.com/1927472?a=1" allowfullscreen="true" allow="camera https://tour-this-com.seehouseat.com; microphone https://tour-this-com.seehouseat.com"><a href="https://tour-this-com.seehouseat.com/1927472?a=1" target="_top">View this tour in a new window.</a></iframe>
            <iframe *ngIf="id == 2" width="100%" height="750" border="0" frameborder="0" src="https://tour-this-com.seehouseat.com/1927473?a=1" allowfullscreen="true" allow="camera https://tour-this-com.seehouseat.com; microphone https://tour-this-com.seehouseat.com"><a href="https://tour-this-com.seehouseat.com/1927473?a=1" target="_top">View this tour in a new window.</a></iframe>
            <iframe *ngIf="id == 3" width="100%" height="750" border="0" frameborder="0" src="https://tour-this-com.seehouseat.com/1927477?a=1" allowfullscreen="true" allow="camera https://tour-this-com.seehouseat.com; microphone https://tour-this-com.seehouseat.com"><a href="https://tour-this-com.seehouseat.com/1927477?a=1" target="_top">View this tour in a new window.</a></iframe>
        </div>
        <div id="details-box" class="col-11 col-sm-11 col-md-6 col-lg-5">
            <h5>{{ item.item }}</h5>
            <br>
            <h3 [innerHtml]="item.address"></h3>
            <h5 innerHtml="{{ item.sleep }}"></h5>
            <br>
            <h5>Please see our video below</h5>
            <br><br>
            <h3>${{ item.price | number:'1.0-0' }} / week</h3>
            <a id="book" class="btn btn-primary" href="/#pricing-title">Book now</a>
            <div class="alert alert-info">
                <b>Before June 27:</b><br>
                3 days rentals are available <br><br>
                <b>After June 27 to the end of summer:</b><br>
                Only weekly rentals are available<br>(from Saturday to Saturday)
            </div>
        </div>
    </div>
<br><br><br><br>
    <div class="row justify-content-center">
        <div class="col-12 col-sm-12 col-md-6">
            <h5 id="more-pict"><b>See more pictures</b></h5>
            <ngx-gallery [options]="galleryOptions" [images]="galleryImages" class="ngx-gallery"></ngx-gallery>

        </div>
    </div>
</div>

<app-calendar></app-calendar>
<app-youtube></app-youtube>
<app-features></app-features>
<app-pricing></app-pricing>

<app-explanation></app-explanation>

<app-contact></app-contact>
<app-footer></app-footer>