import { Component, OnInit } from '@angular/core';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation, NgxGalleryImageSize } from '@kolkov/ngx-gallery';
import { ActivatedRoute } from '@angular/router';
import { VariablesService } from '../variables.service';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent implements OnInit {
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[] = [];
  item = {
    item:"",
    address:"",
    details:"",
    price:"",
    sleep:"",
    frame: ""
  }
  id: number
  constructor(private route: ActivatedRoute, private vars: VariablesService, private auth: AngularFireAuth) { }

  ngOnInit(): void {
    window.scroll(0,0)
    // this.auth.signOut()

    let id = this.route.snapshot.params.id as number
    this.id = id
    this.getImages(id)

    this.getItem(id)

  }

  getItem(id){
    this.vars.getItems().then((res:any) => {
      this.item = res[id]
    })
  }

  getImages(id){
    this.galleryOptions = [
      {
        width: '100%',
        height: '480px',
        thumbnailsColumns: 4,
        imageAnimation: NgxGalleryAnimation.Slide
      },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false
      }
    ];
    var i;
    for (i=0;i<this.vars.getAssetsLength()[id];i++) {
      let array = ["small","medium", "big"]
      var data = {}
      array.forEach(size => {
        data[size] = '/assets/new/'+id+'/'+size+'/'+(i+1)+'.jpg'
      })
      this.galleryImages.push(data)
    }
  }

}
