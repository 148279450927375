<div class="container">
    <div class="row justify-content-center">
        <div class="col-11 col-sm-10 col-md-8">
            <p><br><br>
                <b>4 Great Rental Units Website WWW.atthebeach4you.com</b><br><br>
1. 30 and 23 Farragut Ave Seaside Park NJ 3 houses from Beach, ½ block to
restaurants bars boardwalk wheels rides. ½ block from Seaside Heights
Entire property Newly renovated Everything Brand New , Amazing Balconies,
Ocean and Boardwalk views, Fire Pits Outdoor Showers, Bicycles, Beach
Chairs, Off street parking, Barbecues, Washer/Dryer, Hi speed Wi Fi, Large
Screen T V’s, Outdoor Blue tooth Speaker system for each unit, Central Air
conditioning. Beach badges not included with rental.
<br><br>
30 Farragut Ave Two 3 bedroom units and a Two bedroom unit, Each unit
sleeps 8 and is rented separately and a bonus Brand new 2 bedroom back
house behind it sleeps 4, on same property, can be rented with either unit for
bigger groups or it can be rented individually. Rent One Unit or Rent them all.
$3000 per week (smaller house $1500). Unit 1 is First Floor, Unit 2 is 2 nd Floor
and Unit 3 is Back house (2 bedrooms)<br><br>
Please review Pictures before booking and look at Calendar to see if your
week is available. <br><br>From June 27 th to end of Summer only weekly rentals run
from Saturday to Saturday. Two to Three day rentals available before June
27th. Come visit anytime No appointment necessary

<br><br>
2. 23 Farragut Ave Seaside Park (directly across street from 30 Farragut
Ave) features a Ground floor 4 bedroom unit that sleeps 10. It is also 3
houses from beach, 8 person Jacuzzi, Large Back Yard, Flat screen T V’s in
every room, Hi speed Wi fi, Barbecue, Beach Chairs for Beach, Outdoor
shower, Bicycles for your entire group, outdoor Bumper Pool Table, Washer
Dryer, off street parking for 3 to 4 small to medium sized cars and there is 24
hour Free parking out in front for larger vehicles $3000 per week. Beach
badges not included with rental.<br><br>
Please review Pictures and video before booking and look at Calendar to see
if your week is available. <br><br>From June 27th to end of Summer only weekly
rental from Saturday to Saturday before that 3 day rentals available. <br><br>Come
visit anytime No appointment needed. Sample lease any pay plans on Web.<br><br>
<b>Call or E mail Shore Rentals E mail seasideheights16@gmail.com or call
732-278-4355 Website WWW.atthebeach4you.com<br><br>
All rentals have a $100 clean up fee and a $100 utility fee.<br><br>
Mail and checks go to Shore Rentals P O Box 202 Ridgewood NJ 07451.</b>
            </p>
        </div>
    </div>
</div>