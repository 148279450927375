import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { VariablesService } from 'src/app/variables.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit {

  loading = true
  item = ""
  title = ""

  itemID = ""
  availability = []

  admin = false
  last: Date
  first: Date
  ms = 86400000
  result = []
  months = ["JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE", "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"]
  days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
  constructor(private db: AngularFireDatabase, private auth: AngularFireAuth, private vars: VariablesService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {

    let url = this.router.url
    this.admin = url.indexOf("calendar") > -1

    this.auth.authState.subscribe((res) => {
      this.result = []
      let id = this.route.snapshot.params.id
      this.itemID = id
      this.vars.getItems().then((res:any) => {
        let n = res[id]
        this.item = n.item+", "+n.address
      
        let current = new Date()
        this.fetchData(current)   
      })
      
    })

     
  }

  fetchData(current: Date){
    this.title = this.months[current.getMonth()]+" "+current.getFullYear()
    let first = new Date(current.getFullYear(), current.getMonth(), 1)
    var last = new Date(current.getFullYear(), current.getMonth()+1, 0);
    this.first = first
    this.last = last
    let firstDay = new Date(first).getDay()
    let lastDay = new Date(last).getDay()

    let indexes = []

    var a;
    for (a=0;a<firstDay;a++) {
      indexes.push({
        fill: false,
        available: 2,
        date: 0
      })
    }

    var i;
    for (i=0;i<new Date(last).getDate();i++) {
      indexes.push({
        fill: true,
        available: 0,
        date: i + 1
      })
    }

    var b;
    for (b=0;b<(6 - lastDay);b++) {
      indexes.push({
        fill: false,
        date: 0
      })
    }

    var temp = []
    indexes.forEach((item, index) => {
      if (index % 7 == 0) {
        if (temp.length > 0) {
          this.result.push(temp)

        }
        temp = []
      }
      temp.push(item)
      if (index == indexes.length - 1) {
        this.result.push(temp)
        temp = []
      }
    })

    //fetch DB

    let ref = this.title.replace(/\ /g,"-")
    this.db.object("dates/"+this.itemID+"/"+ref).valueChanges().subscribe((res:any) => {
      if (res != null) {
        this.availability = res.availability
        if (this.availability.length > -1) {
          this.result.forEach((a: any[], i) => {
            a.forEach((b: any, j) => {
              let findIndex = this.availability.findIndex(n => n == b.date)
              this.result[i][j].available = findIndex > -1 ? 1 : 0
            })
          })
        }
      }
      this.loading = false
    })

  }



  updateDate(i, j){

    if (this.admin && this.result[i][j].date != 0) {
      let available = this.result[i][j].available

      if (available == 1) {
        let findIndex = this.availability.findIndex(item => item == this.result[i][j].date)
        if (findIndex > -1) {
          this.availability.splice(findIndex, 1)
        }
      } else {
        this.availability.push(this.result[i][j].date)
      }
      let ref = this.title.replace(/\ /g,"-")
      this.db.object("dates/"+this.itemID+"/"+ref).update({
        availability: this.availability
      }).catch((error) => {
        alert("Error 400")
      })
    }
    

  }

  changeMonth(next){
    this.loading = true
    let val = next ? 3 : -3
    let time = next ? this.last.getTime() : this.first.getTime()
    this.result = []
    let current = new Date(time + (val * this.ms))
    this.availability = []
    this.fetchData(current)
  }

}
