import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { VariablesService } from '../variables.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  items = []
  prices = []
  constructor(private auth: AngularFireAuth, private router: Router, private vars: VariablesService, private db: AngularFireDatabase) { }

  ngOnInit(): void {

    this.auth.authState.subscribe((res) => {
      if (res == null) {
        this.router.navigate(["/"])
      } else {
        this.fetchData()
      }
    })

  }

  fetchData(){
    this.vars.getItems().then((items:any) => {
      this.items = items
    })
    

    var sub: Subscription
    sub = this.db.object("prices").valueChanges().subscribe((res:any) => {
      sub.unsubscribe()
      this.prices = res
      this.items = this.items.map((n,i) => {
        return {...n, price: res[i]}
      })
    })

  }

  update(e, i){
    let val = e.target.value
    let value = val.length == 0 ? 0 : parseFloat(val)
    this.prices[i] = value
    this.db.object("prices").set(this.prices)
  }

  



}
