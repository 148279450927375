import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { VariablesService } from '../variables.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {


  items = []

  reviews = [
    `Very cute place and very well located. You can even see the ocean
    from the top deck! We were 2 families rented both units top and bottom fit 16
    people easily with 2 small kids and it was comfortable for us. Perfect for a fun
    weekend getaway at the shore 3 houses from beach!!`,
    `Very responsive and friendly! The back 2-bedroom residence was perfect
    for 2-4 people and fully functional full kitchen! The location was ideal and just a ½
    block from the beach, which was very convenient given the boardwalk bathrooms
    were closed, so we could instead access the house in a short walk. Clean,
    comfortable and CONVENIENT! A great week getaway`,
    `The place checks all the boxes of what you’ll need to have a great
    beach vacation; bbq grills, fire pit, outdoor seating, beach chairs and bikes – it
    will meet all your needs. The unit is newly renovated. Location is great: 3 houses
    from BYOB beach, Seaside Park beach, and boardwalk (plenty of food options).`,
    `Great host! She helped us with whatever we needed and gave us tons
    of recommendations for the area. The house in three houses from the beach it’s
    a perfect location! 24-hour free street parking on the entire street. You can’t beat
    it. Thanks so much!`,
    `Best host! The house was a perfect cottage located three houses from
    the beach and boardwalk. 30 second walk to the beach and all of the restaurants.
    The house itself was adorable and perfect for our small group. Everything in the
    house was brand new. We had our own little private lounging area that we
    utilized after the beach. There are outdoor showers on the premises and beach
    chairs that can be brought to the beach. We enjoyed our stay so much. We will
    be back! Thank you!!`,
    `This house is in a GREAT location and the owners couldn't be any
    nicer. The place was perfect for the 10 kids that stayed for prom, 4 bedrooms, flat
    screen T V’s in every room and they had a blast in Seaside. They loved the hot
    tub, bikes and just hanging out in the back yard that had everything that they
    could've wanted in a vacation stay.`,
    `Everything about this house is great. From the prime location to the cozy
    spacious front fire pit it was perfect for our family. We had 8 of our family members in
    the 3 bedroom first floor unit which was beautiful and exceptionally clean upon our
    arrival. We would recommend this house to anyone and we cannot wait to come back
    next summer!`,
    `This house was great for our prom weekend. We had a great time and the hot
    tub was amazing. The location right next to the beach was perfect for our group. The
    fully stocked kitchen was perfect for us not having to bring kitchen supplies yet still got
    to cook everything. The large private fenced in backyard was very convenient. We cannot
    wait to tell the upcoming grades about this great rental for the years to come.`,
    `The spacious three-bedroom unit was great for our group. We loved sitting on
    the top balcony to enjoy our morning coffee. There was enough space for all of us to sit
    comfortably inside and outside. The bbq grill was great as we cooked almost every night
    and enjoyed family dinner outside on the patio table set overlooking the boardwalk. We
    loved that we were a hip skip and a jump from the beach which made it easy to have our
    little ones with us.`,
    `This four-bedroom house was great for our group. It slept 10 people
    comfortably and was very clean upon our arrival. The fenced in backyard was great to
    have with our two dogs and the patio furniture in the backyard was perfect for our family
    time sitting by the fire. We had a fantastic week.`,
    `This two-bedroom cottage is perfect for a small family on the search for a
    perfect get away. It is located three houses from the beach and the free 24-hour street
    parking on the block was very convenient. The house was brand new inside and was
    decorated very cute. The outdoor shower was perfect to rinse off after the beach.`,
    `This house was the best. It was such a great fit for our family. Located close
    to the beach and boardwalk so we didn’t have to worry about the kids being too far when
    they traveled up there alone. They enjoyed being by all the ride, games, and ice cream.
    We loved not having to travel to the Jersey shore with a bunch of beach chairs as they
    were provided for us.`
  ]

  users = [
    "Rosemary K.",
    "Mel M.",
    "Blake B.",
    "Claire H.",
    "Austin R.",
    "Linda M.",
    "George G.",
    "Marc T.",
    "Helen M.",
    "Zach D.",
    "Megan C.",
    "Donald T."
  ]

  testimonials = this.reviews.map((a,i)=> {
    return {
      comment: a,
      name: this.users[i]
    }
  })

  constructor(private router: Router, private vars: VariablesService) { }

  ngOnInit(): void {
    window.scroll(0,0)
    
    this.vars.getItems().then((res:any) => {
      this.items = res
    })
    

  }

  toPage(i){
    this.router.navigate(["/details", i])
  }

}
