import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-admin-navbar',
  templateUrl: './admin-navbar.component.html',
  styleUrls: ['./admin-navbar.component.css']
})
export class AdminNavbarComponent implements OnInit {
  calendar = false
  constructor(private auth: AngularFireAuth, private router: Router, private location: Location, private route: ActivatedRoute) { }

  ngOnInit(): void {
    let id = this.route.snapshot.params.id
    this.calendar = id != undefined
  }

  signOut(){
    this.auth.signOut().then(() => {
      this.router.navigate(["/"])
    })
  }

  back(){
    this.location.back()
  }

}
