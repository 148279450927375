<app-admin-navbar></app-admin-navbar>

<div class="container">
    <div class="row justify-content-around">
        <div *ngFor="let item of items; let i = index" class="col-11 col-sm-10 col-md-4 box">
            <div class="box-image">
                <img src="/assets/new/{{ i }}/medium/0.jpg" alt="">
            </div>
            <p>{{ item.item }}</p>
            <p [innerHtml]="item.address"></p>
            <label for="">Price</label>
            <input (keyup)="update($event, i)" type="number" placeholder="Price..." name="item-{{ i }}" [(ngModel)]="item.price" class="form-control">
            <button routerLink="/admin/calendar/{{ i }}" class="btn btn-primary"><i class="fa fa-calendar fa-fw"></i> Calendar</button>
            
        
        </div>
    </div>
</div>