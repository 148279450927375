<div class="container">
    <div id="pricing-title" class="row">
        <div class="col">
            <h4>How to Book</h4>
        </div>
    </div>
    <div class="row justify-content-around">
        <div class="col-11 col-sm-11 order-2 order-md-1 col-md-5">
            <iframe *ngIf="id == 0" width="100%" id="map" frameborder="0" style="border:0" src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJBd7QmyuawYkRDDbfmc64egs&key=AIzaSyB40H1-Jza7pliWzMjI62OC3w67-tmKwQM&zoom=14" allowfullscreen></iframe>
            <iframe *ngIf="id == 1" width="100%" id="map" frameborder="0" style="border:0" src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJiQHmhCuawYkRdn5Tzt2wa4M&key=AIzaSyB40H1-Jza7pliWzMjI62OC3w67-tmKwQM&zoom=14" allowfullscreen></iframe>

        </div>
        <div id="col-pricing" class="col-11 col-sm-11 order-1 order-md-2 col-md-6">
            <div class="alert alert-info">You can come visit anytime. No appointment necessary.</div>
            <ul id="step">
                <li *ngFor="let book of books">{{ book }}</li>
            </ul>

            <p>Combination locks are available and will be given to you when you are going to see the property</p>
            <!-- <ul>
                <li *ngFor="let check of checks"><i class="fa fa-check-circle fa-fw"></i> {{ check }}</li>
            </ul> -->
            <div id="box-button">
                <a target="_blank" href="https://firebasestorage.googleapis.com/v0/b/at-the-beach-4-you-nj.appspot.com/o/Sample%20Pay%20Plan%20%20.doc?alt=media&token=f57c60dd-4a14-44bd-94dd-ab05d7cb603c" class="btn-pricing btn btn-primary btn-sm"><i class="fa fa-file-text fa-fw"></i> Payment plan sample</a><br>
                <a target="_blank" href="https://firebasestorage.googleapis.com/v0/b/at-the-beach-4-you-nj.appspot.com/o/Sample%20Lease%20.doc?alt=media&token=cb556a73-4fc1-49d2-8208-ac700ce5006a" class="btn-pricing btn btn-info btn-sm"><i class="fa fa-file-text fa-fw"></i> Lease sample</a>
            </div>
            
            <div id="notes" class="alert alert-warning">
                <p><b>Notes:</b><br>All rentals have $100 cleaning fee and $100 utility fee.<br>Beach badges are not included in rentals.</p>
            </div>
            

        </div>
        
    </div>
</div>