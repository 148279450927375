<div class="row justify-content-center">
    <div class="col-12 col-sm-10 col-md-6 col-lg-5">

        <div id="login">
            <div id="box">
                <form (ngSubmit)="login(f)" #f="ngForm">
                    <h5>Shore Rentals Admin</h5>
                    <input type="email" ngModel name="email" placeholder="Email" required class="form-control">
                    <input type="password" placeholder="Password" ngModel name="password" required class="form-control">
                    <button [disabled]="loading" class="btn btn-primary full-width">Login</button>
                </form>
                <br>
                <div style="text-align: center;">
                    <a style="color: royalblue; cursor: pointer;" (click)="reset()">Reset password</a>

                </div>
            </div>
        </div>

    </div>
</div>