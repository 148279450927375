import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loading = false
  constructor(private auth: AngularFireAuth, private router: Router) { }

  ngOnInit(): void {

  }

  login(f){
    if (f.valid) {
      let data = f.value
      this.loading = true
      this.auth.signInWithEmailAndPassword(data.email, data.password).then(() => {
        this.router.navigate(["/admin"])
      }).catch((error) => {
        console.log(error)
        alert("Email/password not found")
        this.loading = false
      })
    } else {
      alert("Email/password required")
    }
  }

  reset(){
    let email = prompt("Enter your email")
    if (email != undefined && email != null && email != '') {
      this.auth.sendPasswordResetEmail(email).then(() => {
        alert("Reset password link has been sent")
      }).catch(err => {
        alert("Can't sent link to this email")
      })
    }
    
  }

}
