import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { DetailsComponent } from './details/details.component';
import { LoginComponent } from './login/login.component';
import { AdminComponent } from './admin/admin.component';
import { CalendarComponent } from './admin/calendar/calendar.component';


const routes: Routes = [
  { path: "", component: HomeComponent, pathMatch: "full" },
  { path: "details/:id", component: DetailsComponent, pathMatch: "full" },
  { path: "login", component: LoginComponent, pathMatch: "full" },
  { path: "admin", component: AdminComponent, pathMatch: "full" },
  { path: "admin/calendar/:id", component: CalendarComponent, pathMatch: "full" }



];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
