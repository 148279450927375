<div class="row bg-half">
    <div class="col">
        <div class="container">
            <div id="feature-title" class="row">
                <div class="col">
                    <h4>Features and Amenities</h4>
                </div>
            </div>
            <div id="row-features" class="row justify-content-center">
                <div class="col-5 col-sm-6 col-md-3 col-feat" *ngFor="let feature of features">
                    <i class="fa fa-{{ feature.icon }} fa-2x fa-fw"></i><br><span [innerHTML]="feature.text"></span>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="highlight" class="row">
    <div class="col">
        <p><i class="fa fa-star fa-fw"></i> Only 3 houses away from beach and boardwalk</p>
    </div>
</div>