<div class="container">
    <div *ngIf="main" class="row justify-content-center">
        <div class="col-11 col-sm-11 col-md-9 col-lg-8">
            
            <iframe width="100%" height="400" src="https://youtube.com/embed/dSC9TnJzqGA"></iframe>

        </div>
    </div>

    <div *ngIf="!main" class="row justify-content-center">
        <div class="col-11 col-sm-11 col-md-9 col-lg-8">
            
            <iframe width="100%" height="400" src="https://youtube.com/embed/tLcfhXJoSVo"></iframe>

        </div>
    </div>

</div>
